<div class="page-header-top">
    <div class="container">
        <div class="page-logo">
            <a routerLink="/">
                <!-- When isPh is true -->
                <ng-container *ngIf="isPh">
                    <img *ngIf="!appSession.tenant || !appSession.tenant.logoId" src="/assets/common/images/app-ph-logo-big.png" alt="logo" class="logo-default logo-big" width="400" height="75" />
                    <img *ngIf="!appSession.tenant || !appSession.tenant.logoId" src="/assets/common/images/app-ph-logo-medium.png" alt="logo" class="logo-default logo-medium" width="235" height="50" />
                    <img *ngIf="!appSession.tenant || !appSession.tenant.logoId" src="/assets/common/images/app-ph-logo-small.png" alt="logo" class="logo-default logo-small" width="50" height="50" />
                </ng-container>

                <!-- When isPh is false -->
                <ng-container *ngIf="!isPh">
                    <img *ngIf="!appSession.tenant || !appSession.tenant.logoId" src="/assets/common/images/app-logo-big.png" alt="logo" class="logo-default logo-big" width="400" height="75" />
                    <img *ngIf="!appSession.tenant || !appSession.tenant.logoId" src="/assets/common/images/app-logo-medium.png" alt="logo" class="logo-default logo-medium" width="235" height="50" />
                    <img *ngIf="!appSession.tenant || !appSession.tenant.logoId" src="/assets/common/images/app-logo-small.png" alt="logo" class="logo-default logo-small" width="50" height="50" />
                </ng-container>

                <!-- Tenant logo remains the same -->
                <img *ngIf="appSession.tenant && appSession.tenant.logoId" [src]="remoteServiceBaseUrl + '/TenantCustomization/GetLogo?id=' + appSession.tenant.logoId" alt="logo" class="logo-default" width="400" height="75" />
            </a>
        </div>


        <div class="top-menu">
            <a href="javascript:;" class="menu-toggler responsive-toggler" data-toggle="collapse" data-target=".navbar-collapse">
            </a>
            <ul class="nav navbar-nav pull-right">
                <li *ngFor="let language of languages" class="dropdown dropdown-language">
                    <a (click)="changeLanguage(language.name)">
                        <span [ngClass]="{'text-bold': language.name === currentLanguage.name}" title="{{language.displayName}}"><i class="{{language.icon}}"></i> {{language.displayName}}</span>
                    </a>
                </li>
                <li class="droddown dropdown-separator">
                    <span class="separator"></span>
                </li>
                <!-- DOC: Hide notification bar for now -->
                <li *ngIf="false" headerNotifications class="dropdown dropdown-extended dropdown-notification dropdown-dark" id="header_notification_bar"></li>
                <li class="separator hide"> </li>
                <!-- DOC: Apply "dropdown-dark" class after below "dropdown-extended" to change the dropdown styte -->
                <li class="dropdown dropdown-user dropdown-dark">
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
                        <span class="username username-hide-on-mobile">
                            <i *ngIf="isImpersonatedLogin" class="icon-action-undo font-red-sunglo"></i>
                            {{shownLoginName}}
                        </span>
                        <img *ngIf="profilePicture" id="HeaderProfilePicture" alt="" class="img-circle" [src]="profilePicture"/>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-default">
                        <ng-container *ngIf="isGranted('Pages.Tenant.PreRec.ManageOther')">
                            <li *ngIf="isImpersonatedLogin">
                                <a (click)="backToMyAccount()"><i class="icon-action-undo"></i> <strong>{{l("BackToMyAccount")}}</strong></a>
                            </li>
                            <li *ngIf="isImpersonatedLogin" class="divider"></li>
                            <li class="dropdown-submenu">
                                <a class="open-left"><i class="icon-link"></i> {{l("title.linkedAccounts")}}</a>
                                <ul id="RecentlyUsedLinkedUsers" class="dropdown-menu" style="" *ngIf="recentlyLinkedUsers">
                                    <li *ngFor="let linkedUser of recentlyLinkedUsers">
                                        <a (click)="switchToLinkedUser(linkedUser)" class="recently-linked-user">
                                            {{getShownUserName(linkedUser)}}
                                        </a>
                                    </li>
                                    <li>
                                        <a (click)="showLinkedAccounts()">
                                            <i class="icon-settings"></i> {{l("title.manageAccounts")}}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ng-container>
                        <li>
                            <a id="UserProfileChangePasswordLink" (click)="changePassword()"><i class="icon-key"></i> {{l("title.changePassword")}}</a>
                        </li>
                        <li *ngIf="isGranted('Pages.Tenant.PreRec.ManageOther')">
                            <a id="ShowLoginAttemptsLink" (click)="showLoginAttempts()"><i class="icon-shield"></i> {{l("LoginAttempts")}}</a>
                        </li>
                        <li>
                            <a id="UserProfileChangePictureLink" (click)="changeProfilePicture()"><i class="icon-user"></i> {{l("ChangeProfilePicture")}}</a>
                        </li>
                        <li>
                            <a id="UserProfileMySettingsLink" (click)="changeMySettings()"><i class="icon-settings"></i> {{l("title.mySettings")}}</a>
                        </li>
                        <li class="divider"></li>
                        <li>
                            <a href="javascript:;" (click)="logout()"><i class="icon-logout"></i> {{l("Logout")}}</a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="!chatConnected" class="dropdown dropdown-extended">
                    <a href="javascript:;" class="dropdown-toggle">
                        <img *ngIf="!chatConnected" src="../../../assets/metronic/global/img/loading.gif" tooltip="{{l('note.chatIsConnecting')}}" placement="left"/>
                    </a>
                </li>
                <li class="dropdown dropdown-extended quick-sidebar-toggler" *ngIf="chatEnabled && chatConnected">
                    <span class="sr-only">{{l('note.toggleSidebar')}}</span>
                    <i class="icon-speech"></i>
                    <span id="UnreadChatMessageCount" class="badge badge-danger" [ngClass]="{'hidden': !unreadChatMessageCount}">{{unreadChatMessageCount}}</span>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="page-header-menu">
    <header-menu></header-menu>
</div>
<loginAttemptsModal #loginAttemptsModal></loginAttemptsModal>
<linkedAccountsModal #linkedAccountsModal (modalClose)="getRecentlyLinkedUsers()"></linkedAccountsModal>
<changePasswordModal #changePasswordModal></changePasswordModal>
<changeProfilePictureModal #changeProfilePictureModal></changeProfilePictureModal>
<mySettingsModal #mySettingsModal (modalSave)="onMySettingsModalSaved()"></mySettingsModal>
<notificationSettingsModal #notificationSettingsModal></notificationSettingsModal>
